import { createAppApi } from 'services/api'
import { getCRUDEndpoints } from 'utils/apiUtils'
import apiTags from 'constants/apiTags'
import { deviceQueryParamsHelper } from 'utils/queryParamsHelpers'

export const deviceApi = createAppApi('deviceQuery', {
  tagTypes: [apiTags.device],
  endpoints: builder => ({
    ...getCRUDEndpoints({
      url: '/device',
      builder,
      tags: [apiTags.device],
      name: 'Device'
    }),
    getDevices: builder.query({
      providesTags: [apiTags.device],
      query: (params = {}) => ({
        url: '/device',
        params: deviceQueryParamsHelper(params)
      })
    }),
    bulkTriggerDevicePreview: builder.query({
      query: (params = { limit: 9999 }) => ({
        url: `/device/preview/bulkTriggerDevice`,
        params
      })
    }),
    deviceList: builder.query({
      query: (params = {}) => ({
        url: '/device/list',
        params
      })
    }),
    deviceGrid: builder.query({
      query: (params = {}) => ({
        url: '/device/gridView',
        params: deviceQueryParamsHelper(params)
      })
    }),
    deviceNoc: builder.query({
      query: (params = {}) => ({
        url: '/org/public/noc/general',
        params
      })
    }),
    publicUptime: builder.query({
      query: (params = {}) => ({
        url: '/public/noc/uptime',
        params
      })
    }),
    fetchSupportToken: builder.mutation({
      query: (data = {}) => ({
        url: '/device/fetchSupportToken',
        method: 'POST',
        data
      })
    }),
    getDeviceUpTime: builder.query({
      query: (params = {}) => ({
        url: `/device/noc/uptime`,
        params: deviceQueryParamsHelper(params)
      })
    }),
    ...getCRUDEndpoints({
      name: 'AppVersions',
      url: '/device/appVersion',
      builder,
      tags: [apiTags.appVersion]
    })
  })
})

export const {
  usePostDeviceMutation,
  useGetDeviceQuery,
  useLazyBulkTriggerDevicePreviewQuery,
  useLazyDeviceListQuery,
  useLazyDeviceGridQuery,
  useLazyDeviceNocQuery,
  useLazyPublicUptimeQuery,
  useLazyGetDevicesQuery,
  useGetDevicesQuery,
  useFetchSupportTokenMutation,
  useLazyGetDeviceUpTimeQuery,
  useGetAppVersionssQuery,
  useDeleteAppVersionsMutation
} = deviceApi
