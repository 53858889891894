import React, { useMemo } from 'react'
import { withStyles } from '@material-ui/core'
import { shadeColor } from 'utils'
import classNames from 'classnames'
import { getContrastTextFromRgb } from 'utils/color'
import { isFontAwesomeIcon } from 'utils/featureUtils'

const styles = theme => ({
  typeIconWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: theme.palette[theme.type].typeIcon.boxShadow
  },
  typeIcon: {
    fontSize: '20px',
    color: '#fff',
    cursor: 'pointer'
  },
  faTypeIcon: {
    fontSize: '16px',
    paddingTop: '2px'
  }
})

const LibraryTypeIcon = React.forwardRef(
  (
    {
      classes,
      color,
      wrapHelperClass,
      iconHelperClass,
      iconColor,
      fontSize,
      className,
      icon: Icon = null,
      iconComponentColor,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const { typeIcon, faTypeIcon, typeIconWrap } = classes

    const backgroundImage = useMemo(
      () =>
        color
          ? `linear-gradient(to bottom, ${shadeColor(color, 20)}, ${color})`
          : 'linear-gradient(to bottom, #e5dc58, #c6b72d)',
      [color]
    )

    const isFAIcon = isFontAwesomeIcon(iconHelperClass)

    return (
      <div
        ref={ref}
        className={classNames(typeIconWrap, wrapHelperClass, className)}
        style={{ backgroundImage }}
        {...props}
        id="libraryTypeIcon"
      >
        {!Icon ? (
          <i
            style={{
              color: iconColor || getContrastTextFromRgb(color),
              ...(fontSize && { fontSize })
            }}
            className={classNames(typeIcon, iconHelperClass, {
              'icon-world-wide-web': !iconHelperClass,
              [faTypeIcon]: isFAIcon
            })}
          />
        ) : (
          <Icon
            className={classNames(typeIcon, iconHelperClass, {
              [faTypeIcon]: isFAIcon
            })}
            iconComponentColor={iconComponentColor}
            wrapperClassName={wrapperClassName}
          />
        )}
      </div>
    )
  }
)

export default withStyles(styles)(LibraryTypeIcon)
