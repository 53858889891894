import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Popper from '@material-ui/core/Popper'
import ReactDOM from 'react-dom'

import { _get, _isEmpty } from 'utils/lodash'
import { zIndexes } from 'constants/stylesConstants'

const styles = ({ palette, type }) => ({
  root: {
    zIndex: zIndexes.materialPopup
  },
  content: {
    backgroundColor: palette[type].dropdown.background,
    borderRadius: 5,
    zIndex: zIndexes.materialPopup,
    boxShadow: palette[type].dropdown.boxShadow
  },
  topOffset: {
    paddingTop: 10,
    '& > #arrow': {
      top: 5,
      transform: 'rotate(-45deg)'
    }
  },
  bottomOffset: {
    paddingBottom: 10,
    '& > #arrow': {
      bottom: 5,
      right: 10,
      transform: 'rotate(135deg)'
    }
  },
  leftOffset: {
    paddingLeft: 10,
    '& > #arrow': {
      left: 5,
      transform: 'rotate(225deg)'
    }
  },
  rightOffset: {
    paddingRight: 10,
    '& > #arrow': {
      right: 5,
      transform: 'rotate(45deg)'
    }
  },
  startOffset: {
    '& > #arrow': {
      left: '10px !important'
    }
  },
  endOffset: {
    '& > #arrow': {
      left: 'unset !important',
      right: 10
    }
  },
  arrow: {
    position: 'absolute',
    borderColor: `transparent ${palette[type].dropdown.background} transparent transparent`,
    borderWidth: '0px 10px 10px 0px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    boxShadow: `${palette[type].dropdown.shadowColor} 1px -1px 3px -2px`
  }
})

const portal = document.createElement('div')
portal.setAttribute('id', 'backdrop')
document.body.appendChild(portal)

const MaterialPopup = ({
  children,
  open: openFromParent,
  trigger,
  classes,
  placement = 'bottom',
  on = 'hover',
  innerClasses = {},
  disabled,
  hasArrow = true,
  offset = 10,
  rootClassName,
  preventOverflow,
  modifiers,
  onOpen = f => f,
  onClose = f => f,
  style = {},
  overlayStyles = {},
  closeOnClick,
  contentClassName,
  arrowClassName,
  ...props
}) => {
  const [arrowRef, setArrowRef] = useState(null)
  const popperRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const [didMouseLeaveTrigger, toggleDidMouseLeaveTrigger] = useState(true)
  const [didMouseLeaveContainer, toggleDidMouseLeaveContainer] = useState(true)

  const handleArrowRef = useCallback(node => {
    setArrowRef(node)
  }, [])

  const ref = useRef({
    didClickInside: null,
    anchor: null
  })

  const handleMouseOutside = useCallback(() => {
    ref.current.didClickInside = false
  }, [])

  const closePopup = useCallback(() => {
    ref.current.anchor = null
    setOpen(false)
    onClose()
  }, [onClose])

  const openPopup = useCallback(
    ({ currentTarget }) => {
      ref.current.anchor = currentTarget
      setOpen(true)
      onOpen()
    },
    [onOpen]
  )

  const clickInside = useCallback(() => {
    ref.current.didClickInside = true
    if (closeOnClick) {
      closePopup()
    }
  }, [closeOnClick, closePopup])

  const toggle = useCallback(
    ({ currentTarget }) => {
      clickInside()
      ref.current.anchor = currentTarget
      !open && onOpen()
      setOpen(value => !value)
    },
    [clickInside, onOpen, open]
  )

  const hover = useCallback(
    ({ currentTarget }) => {
      toggleDidMouseLeaveTrigger(false)
      openPopup({ currentTarget })
    },
    [openPopup]
  )

  const mode = useMemo(() => {
    return disabled
      ? {}
      : on === 'click'
      ? { onClick: toggle, onMouseLeave: handleMouseOutside }
      : {
          onMouseLeave: () => toggleDidMouseLeaveTrigger(true),
          onMouseEnter: hover
        }
  }, [disabled, handleMouseOutside, hover, on, toggle])

  const contentListeners = useMemo(
    () => ({
      onMouseLeave:
        on === 'click'
          ? handleMouseOutside
          : () => toggleDidMouseLeaveContainer(true),
      onClick: clickInside,
      onMouseEnter:
        on === 'hover' ? () => toggleDidMouseLeaveContainer(false) : undefined
    }),
    [clickInside, handleMouseOutside, on]
  )

  useEffect(() => {
    const closeModalWhenClickOutside = () => {
      if (ref.current.didClickInside === false) {
        closePopup()
      }
    }
    document.addEventListener('click', closeModalWhenClickOutside)
    return () =>
      document.removeEventListener('click', closeModalWhenClickOutside)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (on === 'hover') {
      if (
        (didMouseLeaveTrigger && didMouseLeaveContainer) ||
        !ref.current.anchor
      ) {
        closePopup()
      }
    }
    //eslint-disable-next-line
  }, [didMouseLeaveTrigger, didMouseLeaveContainer, ref?.current?.anchor])

  useEffect(() => {
    //force position recalculation
    setTimeout(() => {
      forceUpdate()
    }, 0)
    //eslint-disable-next-line
  }, [popperRef.current])

  const memoTrigger = useMemo(() => React.cloneElement(trigger, mode), [
    mode,
    trigger
  ])

  const Backdrop = useCallback(() => {
    return ReactDOM.createPortal(
      <div style={{ ...overlayStyles, zIndex: zIndexes.materialPopup - 1 }} />,
      portal
    )
  }, [overlayStyles])

  return (
    <>
      {openFromParent === undefined
        ? open
        : openFromParent && !_isEmpty(overlayStyles) && <Backdrop />}
      {memoTrigger}
      <Popper
        ref={popperRef}
        style={style}
        anchorEl={ref.current.anchor}
        open={
          openFromParent === undefined
            ? open
            : !!ref.current.anchor
            ? openFromParent
            : undefined
        }
        placement={placement}
        modifiers={{
          flip: {
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              padding: 8
            }
          },
          arrow: {
            enabled: hasArrow,
            element: arrowRef
          },
          ...(preventOverflow && preventOverflow),
          ...(modifiers && modifiers)
        }}
        className={classNames(classes.root, rootClassName)}
        {...props}
      >
        {popperProps => (
          <div
            {...contentListeners}
            className={classNames(classes.container, {
              [classes.topOffset]:
                popperProps.placement.split('-')[0] === 'bottom',
              [classes.bottomOffset]:
                popperProps.placement.split('-')[0] === 'top',
              [classes.rightOffset]:
                popperProps.placement.split('-')[0] === 'left',
              [classes.leftOffset]:
                popperProps.placement.split('-')[0] === 'right',
              [classes.startOffset]:
                ['bottom', 'top'].includes(
                  popperProps.placement.split('-')[0]
                ) && _get(popperProps.placement.split('-'), '[1]') === 'start',
              [classes.endOffset]:
                ['bottom', 'top'].includes(
                  popperProps.placement.split('-')[0]
                ) && _get(popperProps.placement.split('-'), '[1]') === 'end'
            })}
          >
            {hasArrow ? (
              <span
                id="arrow"
                className={classNames(classes.arrow, arrowClassName)}
                ref={handleArrowRef}
              />
            ) : null}
            <div className={classNames(classes.content, contentClassName)}>
              {typeof children === 'function' ? children(closePopup) : children}
            </div>
          </div>
        )}
      </Popper>
    </>
  )
}

MaterialPopup.propTypes = {
  on: PropTypes.oneOf(['click', 'hover']),
  innerClasses: PropTypes.object,
  hasArrow: PropTypes.bool
}

export default withStyles(styles)(MaterialPopup)
