import * as Yup from 'yup'
import moment from 'moment/moment'
import compareVersions from 'compare-versions'
import { _isEmpty } from 'utils/lodash'

import {
  commaSeparatedMacAddress,
  maxStringLength,
  numberField,
  requiredField,
  validActivationCode,
  validMacAddress
} from 'constants/validationMessages'
import { imageValidateSchema } from 'constants/validations'
import {
  activationCodeRegExp,
  commaSeparatedMacAddressesRegExp,
  deviceListIndexRegExp,
  macAddressesRegExp
} from 'constants/regExp'
import {
  BRIGHT_SIGN,
  screenbrightnessRange,
  cecSourceOptions,
  defaultCoords,
  deviceNotificationExceptions,
  deviceStatus,
  screenOrientationOptions,
  VIRTUAL_DEVICE_ALIAS,
  defaultScreenBrightness,
  ACTIVE_OUTDATED_STATUS_COLOR,
  deviceStatusColorByStatus,
  VIDEOWALL_SUPPORTING_PROCESSOR,
  outdatedOSVersions,
  UBUNTU_18_OS_NAME
} from 'constants/deviceConstants'
import {
  defaultVideowallSettings,
  defaultZoneRestrictionSettings
} from 'constants/featureConstants'
import {
  DATE_TIME_VIEW_FORMAT,
  MEDIA_DATE_TIME_S_FORMAT
} from 'constants/dateTimeFormats'
import { initialTimezone } from 'utils/generalUtils'
import { storageGetItem, storageSetItem } from './localStorage'
import { daysData, REBOOT_KEY } from 'constants/reboot'
import { LINUX_OS } from '../constants/libraryConstants/deviceModelConstants'

export const initialValues = {
  name: '',
  alias: '',
  deviceType: null,
  featurePackageId: '',
  disabled: false,
  disabledReason: '',
  clientId: '',
  location: null,
  customLocation: '',
  locationImage: null,
  state: '',
  zip: '',
  city: '',
  country: '',
  coords: defaultCoords,
  group: [],
  tag: [],
  macAddress: '',
  tempMacAddress: '',
  activationCode: '',
  address1: '',
  address2: '',
  hardwareDetails: '',
  description: '',
  primaryInput: '',
  timezone: initialTimezone,
  // TODO change to Auto after tested completely
  screenOrientation: 'Landscape Clockwise',
  licenseExpiration: '',
  logLevel: 'error',
  serviceLevel: 'Standard Support',
  firmwareUpdatingCheck: 152,
  screenSnapshotFrequency: 152,
  checkDeviceReboot: 152,
  deviceConfigCheck: 152,
  weatherRefreshInterval: 152,
  deviceResolutionWidth: 1920,
  deviceResolutionHeight: 1080,
  isAllowChromeLog: true,
  teamviewerId: '',
  remoteSupportId: '',
  serialNumber: '',
  soNumber: '',
  availableFirmwareList: [],
  featureSetting: [defaultZoneRestrictionSettings, defaultVideowallSettings],
  poNumber: '',
  clientPoNumber: '',
  invoiceNumber: '',
  incognitoBrowser: true,
  virtualDeviceWhiteListIpCidr: [],
  virtualDeviceWhiteListIpCidrEnable: false,
  screenBrightness: defaultScreenBrightness,
  deviceSoundLevel: 0,
  deviceAudioOutput: null,
  unassignTRLFrom: '',
  unAssignTRLFromDeviceName: '',
  deviceRefreshLicenseId: '',
  transferredExpirationDate: '',
  deviceRefreshLicenseLabel: ''
}

export const getValidationSchema = (
  t,
  role,
  isVirtualDevice,
  isEdit,
  impersonated,
  permissions,
  licensesAvailable
) =>
  Yup.object().shape({
    alias: Yup.string().matches(
      /[A-Za-z1-9 \-.!&@#$%-_\\()']{2,}/,
      t('device alias validation message')
    ),
    deviceResolutionWidth: Yup.number(t(numberField)).required(
      t(requiredField)
    ),
    deviceResolutionHeight: Yup.number(t(numberField)).required(
      t(requiredField)
    ),
    ...(!isEdit &&
      role.org && {
        name: Yup.string().restrictedCharacters().required(t(requiredField)),
        deviceType: Yup.object().required(t(requiredField)).nullable(),
        activationCode: Yup.string()
          .min(12, t('Activation code must consist of 12 characters'))
          .max(12, t('Activation code must consist of 12 characters'))
          .matches(activationCodeRegExp, t(validActivationCode))
          .required(t(requiredField)),
        address1: Yup.string()
          .restrictedCharacters()
          .required(t(requiredField)),
        city: Yup.string()
          .matches(/[A-Za-z -1-9]*/, '')
          .required(t(requiredField)),
        state: Yup.string().restrictedCharacters().required(t(requiredField)),
        zip: Yup.string().restrictedCharacters().required(t(requiredField)),
        country: Yup.string().restrictedCharacters().required(t(requiredField))
      }),
    ...(licensesAvailable &&
      ((role.org && impersonated && isEdit) || (role.system && !isEdit)) && {
        deviceLicenseId: Yup.string().required(t(requiredField)).nullable()
      }),
    ...(role.system && {
      name: Yup.string().restrictedCharacters().required(t(requiredField)),
      clientId: Yup.string().required(t(requiredField)),
      featurePackageId: Yup.mixed().required(t(requiredField)),
      deviceType: Yup.object().required(t(requiredField)).nullable(),
      teamviewerId: Yup.string().min(9).max(10),
      remoteSupportId: Yup.string()
        .nullable()
        .matches(
          /^\d{9,12}$/,
          t('Support ID must be between min and max digits', {
            min: 9,
            max: 12
          })
        ),
      serialNumber: Yup.string()
        .matches(
          /^[-a-zA-Z0-9]+$/,
          t(
            'The serial number may only contain alphabets, numbers, and hyphen(-)'
          )
        )
        .required(t(requiredField)),
      disabledReason: Yup.string()
        .restrictedCharacters()
        .when('disabled', (disabled, schema) => {
          return disabled ? schema.required(t(requiredField)) : schema
        }),
      poNumber: Yup.string()
        .matches(
          /^[-a-zA-Z0-9]+$/,
          t('The SO Number may only contain alphabets, numbers, and hyphen(-)')
        )
        .required(t(requiredField)),
      clientPoNumber: Yup.string().matches(
        /^[-a-zA-Z0-9]+$/,
        t(
          'The Client PO Number may only contain alphabets, numbers, and hyphen(-)'
        )
      ),
      invoiceNumber: Yup.string()
        .matches(
          /^[-a-zA-Z0-9]+$/,
          t('The SO Number may only contain alphabets, numbers, and hyphen(-)')
        )
        .required(t(requiredField))
    }),
    ...(isVirtualDevice && {
      timezone: Yup.string().required(requiredField)
    }),
    ...(!isVirtualDevice && {
      ...(role.system && {
        macAddress: Yup.string()
          .required(t(requiredField))
          .matches(
            commaSeparatedMacAddressesRegExp,
            t(commaSeparatedMacAddress)
          ),
        tempMacAddress: Yup.string().test(
          'tempMacAddress',
          t(validMacAddress),
          val => (!!val ? macAddressesRegExp.test(val) : true)
        )
      }),
      locationImage: imageValidateSchema,
      location:
        permissions?.deviceLocationImagePermissions?.read &&
        Yup.object().required(t(requiredField)).nullable(),
      customLocation: Yup.string().when('location', (location, schema) => {
        return location?.value === -1
          ? Yup.string().restrictedCharacters().required(t(requiredField))
          : schema
      }),
      address1: Yup.string().restrictedCharacters().required(t(requiredField)),
      city: Yup.string()
        .matches(/[A-Za-z -1-9]*/, '')
        .required(t(requiredField)),
      zip: Yup.string().restrictedCharacters().required(t(requiredField)),
      state: Yup.string()
        .restrictedCharacters()
        .required(t(requiredField))
        .trim()
        .max(100, maxStringLength(t)),
      country: Yup.string().restrictedCharacters().required(t(requiredField))
    })
  })

export const isLegacyBrowserVersion = browserVersion => {
  const version =
    /^chrome \d+$/i.test(browserVersion) &&
    parseInt(browserVersion.match(/\d+$/i)[0])
  return version && version <= 65
}

export const latestAppVersionComparer = (a, b) => {
  if (compareVersions.compare(a.version, b.version, '=')) {
    return 0
  }
  return compareVersions.compare(a.version, b.version, '>') ? -1 : 1
}

export const getTimezone = device => device?.timezone || device?.timeZone || '0'

export const getTimeZoneOffset = device => device?.timezoneOffset || '0'

export const getDeviceIndexFromMessage = message => {
  const match = deviceListIndexRegExp.exec(message)

  if (match) {
    return match[1]
  }
  return null
}

export const getCurrentDeviceTime = (device, fallback) => {
  if (!getTimezone(device)) {
    return fallback
  }
  return moment().utcOffset(getTimezone(device)).format(DATE_TIME_VIEW_FORMAT)
}

export const cecDataConverter = {
  fromBe: data => {
    const {
      isMuted,
      isScreenIrSensorEnabled,
      screenSchedule,
      screenInput,
      screenVolume,
      screenContrast,
      screenBrightness
    } = data

    return {
      ...data,
      screenBrightness: convertBrightness(
        screenBrightness,
        screenbrightnessRange.fromBe
      ),
      screenSchedule: _isEmpty(screenSchedule)
        ? {
            screenDisableChoice: 'null'
          }
        : screenSchedule,
      isMuted: !!isMuted,
      screenVolume: isMuted ? 0 : screenVolume,
      screenContrast: screenContrast ? screenContrast : 1,
      isScreenIrSensorEnabled: data.hasOwnProperty('isScreenIrSensorEnabled')
        ? !!isScreenIrSensorEnabled
        : true,
      screenInput: cecSourceOptions.some(({ value }) => value === screenInput)
        ? screenInput
        : cecSourceOptions[0].value
    }
  },
  toBe: values => ({
    ...values,
    isMuted: values.screenVolume === 0,
    screenVolume: values.screenVolume ? values.screenVolume : 1,
    screenBrightness: convertBrightness(
      values.screenBrightness,
      screenbrightnessRange.toBe
    )
  })
}

export const getScreenOrientationValue = (row = {}, t) => {
  const { deviceReportedScreenOrientation, screenOrientation } = row
  const orientation = screenOrientationOptions(t).find(
    ({ value }) =>
      value === (deviceReportedScreenOrientation || screenOrientation)
  )

  if (screenOrientation === 'Auto') {
    let str = `${t('Auto')}`
    if (deviceReportedScreenOrientation) {
      str += ` (${
        orientation ? orientation.label : deviceReportedScreenOrientation
      }) `
    }
    return str
  }

  return orientation ? orientation.label : t('Auto')
}

export const getDeviceCardPopupSize = isPortraitImg =>
  isPortraitImg ? { height: 465, width: 620 } : { height: 620, width: 362 }

export const isMoreTimePassedAfter = (date, amount = 1, unit = 'days') =>
  moment().isAfter(moment(date).add(amount, unit))

export const getDeviceMeta = ({
  deviceReportedScreenOrientation,
  deviceReportedResolutionWidth,
  deviceReportedResolutionHeight,
  deviceReportedPrimaryInput,
  deviceReportedScreenBrightness,
  deviceReportedSoundLevel,
  deviceReportedAudioOutput,
  deviceReportedAudioOutputs
}) => ({
  deviceReportedScreenOrientation,
  deviceReportedResolutionWidth,
  deviceReportedResolutionHeight,
  deviceReportedPrimaryInput,
  deviceReportedScreenBrightness:
    deviceReportedScreenBrightness &&
    convertBrightness(
      parseInt(deviceReportedScreenBrightness),
      screenbrightnessRange.fromBe
    ),
  deviceReportedSoundLevel,
  deviceReportedAudioOutput,
  deviceReportedAudioOutputs
})

export const getNewFirmwareVersion = availableFirmwareList => {
  if (availableFirmwareList?.length) {
    const firmware = availableFirmwareList.reduce((a, b) =>
      compareVersions.compare(a.version, b.version, '>') ? a : b
    )

    return firmware?.version || ''
  }

  return ''
}

export const getStatusInfo = ({
  availableFirmwareList,
  status,
  firmware,
  lastCheckInUTC,
  t,
  disabledBy,
  disabledAt,
  role
}) => {
  if (status === deviceStatus.inactive) {
    return {
      needsAttention: true,
      headerTitle: t('Entity needs attention', { entity: 'Device' }),
      title: t('Inactive Device Card playing content')
    }
  }

  if (
    availableFirmwareList?.length > 0 &&
    getNewFirmwareVersion(availableFirmwareList) !== firmware &&
    status !== deviceStatus.dormant
  ) {
    return {
      needsAttention: true,
      headerTitle: t('Entity needs attention', { entity: 'Device' }),
      title: t(
        'A new version of device application is available for this device'
      )
    }
  }

  if (status === deviceStatus.dormant) {
    let title = ''
    if (lastCheckInUTC === null) {
      title = t('Device has never checked tooltip text')
    }

    const diff = moment().diff(moment(lastCheckInUTC), 'days')

    if (diff >= 30) {
      title = t('Migrated device with no check in for 30+ days tooltip text')
    }

    if (diff >= 120) {
      title = t('Device has not checked in for 120+ days tooltip text')
    }

    return {
      needsAttention: true,
      headerTitle: t('Dormant reason'),
      title
    }
  }
  if (status === deviceStatus.disabled) {
    if (role.system)
      return {
        needsAttention: true,
        headerTitle: t('Disabled by user', {
          user: !disabledBy
            ? t('System')
            : `${disabledBy?.firstName} ${disabledBy?.lastName}`
        }),
        title: disabledBy ? disabledAt : ''
      }

    if (role.org || role.enterprise) {
      return {
        needsAttention: true,
        headerTitle: !disabledBy
          ? t('Disabled by System')
          : t(`Disabled due to inactivity`)
      }
    }
  }

  return {
    needsAttention: false,
    headerTitle: '',
    title: ''
  }
}

export const getDevicePreviewUrl = (data = {}) => {
  const { uri, thumbUri } = data

  return uri || thumbUri
}

export const isHandledConflict = (...reducers) =>
  reducers.some(
    ({ error }) =>
      !_isEmpty(error) &&
      error.code === 409 &&
      deviceNotificationExceptions.includes(error.exception)
  )

const isVideowallSupportingProcessor = processor =>
  (processor || '').toLowerCase() ===
  VIDEOWALL_SUPPORTING_PROCESSOR.toLowerCase()

export const isBrightSignType = type => type?.alias === BRIGHT_SIGN
export const isVirtualDeviceType = type => type?.alias === VIRTUAL_DEVICE_ALIAS
export const isVideowallDeviceType = (type, device) =>
  isBrightSignType(type) ||
  (type?.operationSystem === LINUX_OS &&
    isVideowallSupportingProcessor(type?.processor)) ||
  isVideowallSupportingProcessor(device?.processor)

export const setDeviceRebootStorage = ({ deviceId }) => {
  const storageData = storageGetItem(REBOOT_KEY)

  const rebootData = storageData ? JSON.parse(storageData) : []
  const deviceHasSetReboot = rebootData.find(
    device => device.deviceId === deviceId
  )

  if (deviceHasSetReboot) {
    const updatedData = rebootData.map(device => {
      if (device.deviceId === deviceId) {
        return {
          ...device,
          createdAt: moment().format(MEDIA_DATE_TIME_S_FORMAT)
        }
      }

      return device
    })

    storageSetItem(REBOOT_KEY, JSON.stringify(updatedData))
  } else {
    rebootData.push({
      deviceId,
      createdAt: moment().format(MEDIA_DATE_TIME_S_FORMAT)
    })

    storageSetItem(REBOOT_KEY, JSON.stringify(rebootData))
  }
}

const getRebootRecurringDate = ({
  day,
  rebootTime,
  isNextWeekReboot = false
}) => {
  const recurringDate = moment()
    .day(daysData[day].slice(0, -1))
    .set('hour', rebootTime[0])
    .set('minute', rebootTime[1])
    .second(rebootTime[2])

  return !isNextWeekReboot ? recurringDate : recurringDate.add(1, 'week')
}

const getRebootList = ({ rebootDays, rebootTime, isNextWeekReboot }) => {
  const recurringRebootList = []

  rebootDays.split('|').forEach(day => {
    const recurringDate = getRebootRecurringDate({
      day,
      rebootTime,
      isNextWeekReboot
    })

    if (recurringDate.isAfter(moment())) {
      recurringRebootList.push(recurringDate)
    }
  })

  return recurringRebootList
}

export const getNextRecurringReboot = ({ rebootDays, rebootTime }) => {
  let recurringRebootList = getRebootList({
    rebootDays,
    rebootTime
  })

  if (rebootDays && rebootTime && !recurringRebootList.length) {
    recurringRebootList = getRebootList({
      rebootDays,
      rebootTime,
      isNextWeekReboot: true
    })
  }

  return [...recurringRebootList]
    .sort((a, b) => moment(b).format('X') - moment(a).format('X'))
    .at(-1)
}

export const getResolution = ({
  deviceReportedResolutionWidth,
  deviceReportedResolutionHeight
}) => {
  if (deviceReportedResolutionWidth && deviceReportedResolutionHeight) {
    return `${deviceReportedResolutionWidth}x${deviceReportedResolutionHeight}`
  }
}

export const isUbuntuDeviceSecurity = ({ deviceSecurity }) =>
  deviceSecurity?.name?.includes('Ubuntu')

export const getDeviceSecurityStatus = device =>
  isUbuntuDeviceSecurity(device)
    ? device.deviceSecurity?.securityStatus
    : 'Unknown'

export const isDeviceSecurityAvailable = ({ virtualDevice, osVersion }) =>
  _isEmpty(virtualDevice) && osVersion

export const getAvgMemory = ({ avgMemory, memoryUtilization, totalMemory }) => {
  if (avgMemory) {
    return avgMemory
  } else if (totalMemory && memoryUtilization) {
    return Math.round(
      (memoryUtilization.toLowerCase().replace('gb', '') * 100) /
        totalMemory.toLowerCase().replace('gb', '')
    )
  }
}

export const convertBrightness = (originalValue, conversionRange) => {
  const { desiredStart } = conversionRange
  let convertedAmount
  if (desiredStart) {
    convertedAmount = originalValue * 20 || 1
  } else {
    convertedAmount = originalValue === 1 ? 0 : Math.round(originalValue / 20)
  }
  return convertedAmount
}

export const getDeviceStatusColor = ({ status, lastCheckInUTC }) => {
  if (
    status === deviceStatus.active &&
    lastCheckInUTC &&
    moment().diff(moment(lastCheckInUTC), 'hours') >= 1
  ) {
    return ACTIVE_OUTDATED_STATUS_COLOR
  }

  return (
    deviceStatusColorByStatus[status] ||
    deviceStatusColorByStatus[deviceStatus.disabled]
  )
}

export const isTRLTag = tag => tag.title.includes('TRL')

export const hasTRLTagAssigned = device => {
  if (_isEmpty(device.tag)) return false
  return device.tag.some(tag => isTRLTag(tag))
}

export const isOutdatedOSVersion = device => {
  const { osVersion, createdAt } = device

  if (!osVersion) return

  if (osVersion.includes(UBUNTU_18_OS_NAME))
    return moment().diff(moment(createdAt), 'years') >= 3

  return outdatedOSVersions.some(outdatedVersion =>
    osVersion.startsWith(outdatedVersion)
  )
}
