import { queryParamsHelper } from './index'

export const playlistQueryParamsHelper = params =>
  queryParamsHelper(
    params,
    ['tag', 'group', 'mediaFeatureNames', 'status'],
    [],
    ['playlistType']
  )

export const deviceQueryParamsHelper = params =>
  queryParamsHelper(
    {
      ...params,
      ...(!!params.dormant && { status: null }),
      ...(!!params.firmware && { exact: 1 })
    },
    ['group', 'tag'],
    [],
    ['mediaFeature', 'deviceTypeId']
  )
