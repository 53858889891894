export const operators = {
  equal: 'eq',
  notEqual: 'not eq',
  includes: 'in',
  notIncludes: 'not in',
  greaterThan: 'gt',
  lessThan: 'lt',
  greaterThanOrEqual: 'gte',
  lessThanOrEqual: 'lte'
}

export const operatorOptions = [
  { value: operators.equal, label: 'Equal' },
  { value: operators.notEqual, label: 'Not Equal' },
  { value: operators.includes, label: 'Includes' },
  { value: operators.notIncludes, label: 'Not Includes' },
  { value: operators.greaterThan, label: 'Greater Than' },
  { value: operators.lessThan, label: 'Less Than' },
  { value: operators.greaterThanOrEqual, label: 'Greater Than or Equal' },
  { value: operators.lessThanOrEqual, label: 'Less Than or Equal' }
]
