import React, { useState, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { compose } from '@reduxjs/toolkit'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Slide,
  Fade,
  ClickAwayListener
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { CircleIconButton } from 'components/Buttons'
import HelpIconLink from 'components/HelpIconLink'
import useHelpLink from 'hooks/useHelpLink'
import Scrollbars from 'components/Scrollbars'
import useTopBanner from 'hooks/api/useTopBanner'
import { TextWithTooltip } from 'components/Typography'
import { zIndexes } from 'constants/stylesConstants'

const styles = ({ palette, type, fontSize, lineHeight }) => ({
  sideModalWrap: {
    position: 'fixed',
    top: 78,
    zIndex: zIndexes.sideModal,
    height: 'calc(100% - 78px)',
    width: '100%',
    maxWidth: '1600px'
  },
  hasTopBanner: {
    top: 113,
    height: 'calc(100% - 113px)'
  },
  sideModalWrapFullScreen: {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 11
  },
  sideModal: {
    height: '100%',
    background: palette[type].sideModal.background,
    borderRadius: '0 8px 8px 0',
    marginLeft: 'auto',
    boxShadow: palette[type].sideModal.boxShadow
  },
  sideModalInner: {
    height: '100%'
  },
  leftRadius: {
    borderRadius: '8px'
  },
  sideModalContent: {
    flex: '1 1 auto',
    maxHeight: '100% !important',
    height: '100%',
    overflow: 'visible !important'
  },
  sideModalHeader: {
    padding: '20px 35px'
  },
  sideModalFooter: {
    padding: '25px 20px 20px 20px ',
    borderTop: `1px solid ${palette[type].sideModal.footer.border}`,
    backgroundColor: palette[type].sideModal.footer.backgroundColor
  },
  sideModalHeaderSubtitle: {
    color: palette[type].sideModal.header.subtitleColor,
    fontSize: fontSize.primary,
    lineHeight: lineHeight.primary
  },
  icon: {
    color: palette[type].sideModal.header.titleColor,
    margin: '5px 0',
    marginLeft: '20px'
  },
  secondFooterColor: { backgroundColor: palette[type].sideModal.background },
  infoIconWrap: {
    padding: '5px 20px',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: palette[type].pageContainer.header.infoIcon.border
  },
  restIconWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0px'
  },
  infoIconWrapLeftPadding: {
    paddingLeft: '5px'
  },
  restIconWrapRightBorder: {
    padding: '5px 20px',
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: palette[type].pageContainer.header.infoIcon.border
  },
  iconColor: {
    fontSize: '1.3rem'
  }
})

const TRANSITION_DURATION = 250

const SideModal = ({
  history,
  classes,
  children,
  title,
  subtitle,
  closeLink,
  headerClassName = '',
  titleWrapperClass = '',
  wrapClassName = '',
  innerClassName = '',
  childrenWrapperClass,
  footerClassName,
  footerLayout,
  width = '90%',
  animated = true,
  fullScreen = false,
  helpPageName = '',
  scrollContent = true,
  handleClose,
  headerGridClassName = '',
  headerRestComponent,
  closeOnOutsideClick
}) => {
  const [mounted, setMounted] = useState(true)
  const [transitionDuration] = useState(animated ? TRANSITION_DURATION : 0)

  const helpUrl = useHelpLink(helpPageName)
  const topBanner = useTopBanner()

  const closeAnimated = useCallback(() => {
    const timer = setTimeout(() => {
      if (handleClose) {
        handleClose()
      } else {
        history.push({
          pathname: closeLink,
          state: {
            fromMedia: true
          }
        })
      }
      clearTimeout(timer)
    }, transitionDuration)
  }, [transitionDuration, history, closeLink, handleClose])

  const closeInstantly = useCallback(() => {
    if (handleClose) {
      handleClose()
    } else {
      history.push(closeLink)
    }
  }, [history, closeLink, handleClose])

  const handleCloseClick = useCallback(() => {
    setMounted(false)
    return animated ? closeAnimated() : closeInstantly()
  }, [setMounted, animated, closeAnimated, closeInstantly])

  const backgroundGradient = useMemo(() => {
    if (width === '100%') return ' transparent'

    const safeWidthNumericValue = Number(width.slice(0, -1))
    const greyOutEnd = `${Math.min(safeWidthNumericValue + 8, 100)}%`

    return `linear-gradient(to left, rgba(255, 255, 255, 0) 5%, rgba(90, 90, 90, 0.16) ${width}, rgba(255, 255, 255, 0) ${greyOutEnd}, rgba(255, 255, 255, 0) 100%)`
  }, [width])

  const ApplyScrollbars = useCallback(
    ({ children }) => {
      if (!fullScreen) {
        if (scrollContent) {
          return <Scrollbars>{children}</Scrollbars>
        }
      }
      return <>{children}</>
    },
    [fullScreen, scrollContent]
  )
  return (
    <Fade mountOnEnter unmountOnExit in={mounted} timeout={transitionDuration}>
      <div
        className={classNames(wrapClassName, {
          [classes.sideModalWrap]: !fullScreen,
          [classes.sideModalWrapFullScreen]: fullScreen,
          [classes.hasTopBanner]: topBanner.visible
        })}
        style={{
          background: backgroundGradient
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (closeOnOutsideClick) {
              handleCloseClick()
            }
          }}
        >
          <Slide
            direction="left"
            mountOnEnter
            unmountOnExit
            in={mounted}
            timeout={transitionDuration}
          >
            <Grid
              container
              direction="column"
              alignItems="stretch"
              wrap="nowrap"
              style={{
                width: fullScreen ? '100%' : width
              }}
              className={classNames(
                classes.sideModal,
                { [classes.leftRadius]: fullScreen },
                innerClassName
              )}
              id="sideModal"
            >
              <Grid item>
                <header
                  className={[classes.sideModalHeader, headerClassName].join(
                    ' '
                  )}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={headerGridClassName}
                  >
                    <Grid item className={titleWrapperClass}>
                      <TextWithTooltip
                        maxWidth={520}
                        color="title.primary"
                        variant="big"
                        weight="bold"
                      >
                        {title}
                      </TextWithTooltip>
                      {subtitle && (
                        <TextWithTooltip
                          maxWidth={520}
                          rootClassName={classes.sideModalHeaderSubtitle}
                        >
                          {subtitle}
                        </TextWithTooltip>
                      )}
                    </Grid>
                    <Grid item>
                      <Grid container>
                        {headerRestComponent && (
                          <Grid
                            item
                            className={classNames(classes.restIconWrap, {
                              [classes.restIconWrapRightBorder]: !helpUrl
                            })}
                          >
                            {headerRestComponent}
                          </Grid>
                        )}
                        {!!helpUrl && (
                          <Grid
                            item
                            className={classNames(classes.infoIconWrap, {
                              [classes.infoIconWrapLeftPadding]: headerRestComponent
                            })}
                          >
                            <HelpIconLink
                              link={helpUrl}
                              classes={{ circleIcon: classes.iconColor }}
                            />
                          </Grid>
                        )}
                        {(closeLink || handleClose) && (
                          <Grid item>
                            <CircleIconButton
                              className={[classes.icon, 'hvr-grow'].join(' ')}
                              onClick={handleCloseClick}
                            >
                              <Close />
                            </CircleIconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </header>
              </Grid>
              <ApplyScrollbars>
                <Grid
                  item
                  className={classNames(
                    classes.sideModalContent,
                    childrenWrapperClass
                  )}
                >
                  {children}
                </Grid>
              </ApplyScrollbars>

              <Grid item>
                {footerLayout ? (
                  <footer
                    className={classNames(
                      classes.sideModalFooter,
                      footerClassName
                    )}
                  >
                    <Grid container>{footerLayout}</Grid>
                  </footer>
                ) : null}
              </Grid>
            </Grid>
          </Slide>
        </ClickAwayListener>
      </div>
    </Fade>
  )
}

const isPercentageString = (props, propName, componentName) => {
  if (!props[propName]) {
    return
  }

  const regex = /\d{1,3}%/

  if (!regex.test(props[propName])) {
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a valid percentage string.`
    )
  }
}

SideModal.propTypes = {
  width: isPercentageString,
  closeOnOutsideClick: PropTypes.bool
}

export default compose(withRouter, withStyles(styles))(SideModal)
